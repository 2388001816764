import Select from "react-select";
import { useState } from "react";
import { fetchItems } from "../store/actions/actionCreator";
import { useDispatch } from "react-redux";

export default function ItemSearchBox({ types, statuses }) {
	const [form, setForm] = useState({});
	const [selectedTypeId, setSelectedTypeId] = useState(null);
	const dispatch = useDispatch();
	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		if (value === "") {
			obj[name] = undefined;
		}
		setForm(obj);
	};
	const allObj = {value: 0, label: "All"}
	const typeList = [allObj, ...types]

	const handleSearch = (e) => {
		e.preventDefault();
		if (selectedTypeId > 0) {
			form.TypeId = selectedTypeId;
		} else {
			form.TypeId = null;
		}
		dispatch(fetchItems(form));
	};

	function handleTypeSelect(e) {
		setSelectedTypeId(e.value);
	}

	return (
		<div className="fixed flex flex-col gap-8 h-2/3 border search-box text-themeBlack shadow-lg bg-white">
			<form className="flex flex-col py-8 px-8 rounded-lg text-sm gap-6">
				<div className="flex flex-col gap-2">
					<p className="text-sm font-semibold">Tipe:</p>
					<Select
						options={typeList}
						className="w-full py-0.5 px-1"
						onChange={handleTypeSelect}
						value={types.filter(function (type) {
							return type.value === selectedTypeId;
						})}
						placeholder={typeList[0].label}
					/>
				</div>
				<div className="flex flex-col gap-2 px-1">
					<p className="text-sm font-semibold">Nomor seri:</p>
					<input
						type="text"
						className="border-2 rounded-sm px-2 py-1 text-black"
						placeholder="Search"
						onChange={changeHandler}
						name="searchSN"
					></input>
				</div>
				<div className="flex flex-col gap-2 px-1">
					<p className="text-sm font-semibold">Status:</p>
					<select
						className="border-2 rounded-sm px-2 py-1 text-black"
						onChange={changeHandler}
						name="StatusId"
					>
						<option value="" selected >All</option>
						{statuses.map(status => {
							return <option value={status.id} key={status.id}>{status.name}</option>
						})}
					</select>
				</div>
				<button
					className="bg-themeGreen text-white px-2 py-2 mt-4 font-semibold search-btn"
					onClick={handleSearch}
				>
					Cari
				</button>
			</form>
		</div>
	);
}
