import Navbar from "../components/Navbar";
import ItemSearchBox from "../components/ItemSearchBox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchItems,
	downloadCertificate,
	fetchTypes,
	fetchStatuses,
	downloadAllCertificate,
} from "../store/actions/actionCreator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function HomePage() {
	const [snSort, setSnSort] = useState("");
	const [nameSort, setNameSort] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const items = useSelector((state) => {
		return state.items.items;
	});
	let isDownloading = false;
	function handleDownloadAll() {
		if (!isDownloading) {
			isDownloading = true;
			setIsLoading(true);
			dispatch(downloadAllCertificate(certPathsList))
				.catch((error) => console.log(error))
				.finally(() => {
					setIsLoading(false);
					isDownloading = false; // Reset the flag after download is complete or fails
				});
		}
	}

	const certPathsList = [];
	items.map((el) => {
		if (el.Certificates.length > 0) {
			certPathsList.push(el.Certificates[0].url);
		}
	});

	function sorterAscSn(a, b) {
		return a.serialNumber.localeCompare(b.serialNumber, undefined, {
			numeric: true,
		});
	}
	function sorterDescSn(a, b) {
		return b.serialNumber.localeCompare(a.serialNumber, undefined, {
			numeric: true,
		});
	}

	function sorterAscName(a, b) {
		return a.Type.name.localeCompare(b.Type.name, undefined, {
			numeric: true,
		});
	}

	function sorterDescName(a, b) {
		return b.Type.name.localeCompare(a.Type.name, undefined, {
			numeric: true,
		});
	}

	if (snSort === "ASC") {
		items.sort(sorterAscSn);
	} else if (snSort === "DESC") {
		items.sort(sorterDescSn);
	}

	if (nameSort === "ASC") {
		items.sort(sorterAscName);
	} else if (nameSort === "DESC") {
		items.sort(sorterDescName);
	}

	const types = useSelector((state) => {
		return state.types.types;
	});
	const statuses = useSelector((state) => {
		return state.statuses.statuses;
	});

	function handleDownload(id, serialNumber) {
		dispatch(downloadCertificate(id, serialNumber)).catch((error) =>
			console.log(error)
		);
	}

	useEffect(() => {
		dispatch(fetchItems());
		dispatch(fetchTypes());
		dispatch(fetchStatuses());
	}, []);
	return (
		<div className="flex flex-col w-screen h-screen">
			<Navbar />
			<main className="grow px-10 py-10 flex gap-8 mt-20">
				<ItemSearchBox
					types={types}
					statuses={statuses}
				/>
				<div
					className="w-full rounded-lg px-4 py-2 text-md"
					style={{ marginLeft: "300px" }}
				>
					{items.length > 0 ? (
						<div>
							<div className="flex justify-between w-full">
								{isLoading == false ? certPathsList.length > 0 && (
									<h1
										className="text-right text-sm text-blue-600 underline cursor-pointer mb-6"
										onClick={() => handleDownloadAll()}
									>
										Download semua sertifikat
									</h1>
								) : (
									<h1 className="text-right text-sm mt-4">
										Download sedang berlangsung...
									</h1>
								)}
								<h1 className="mb-6 text-right">
									Jumlah item yang ditampilkan:{" "}
									<span className="font-bold">{items.length}</span>
								</h1>
							</div>

							<table className="w-full">
								<thead className="">
									<tr className="text-left text-sm border-b bg-themeGreen text-white py-2">
										<th className="px-2 py-1 items-center gap-2">
											<div className="flex items-center gap-2">
												<p>Nama Alat</p>
												<input
													type="checkbox"
													id="hacky-input-name"
													className=""
												/>
												<label
													for="hacky-input-"
													className="rotate cursor-pointer"
												>
													<FontAwesomeIcon
														icon={faCaretDown}
														onClick={() => {
															if (nameSort === "ASC") {
																setNameSort("DESC");
																setSnSort("");
															} else {
																setNameSort("ASC");
																setSnSort("");
															}
														}}
													/>
												</label>
											</div>
										</th>
										<th className="px-2 py-1 flex items-center gap-2">
											<div className="flex items-center gap-2">
												<p>Nomor Seri</p>
												<input
													type="checkbox"
													id="hacky-input-sn"
												/>
												<label
													for="hacky-input-sn"
													className="rotate cursor-pointer"
												>
													<FontAwesomeIcon
														icon={faCaretDown}
														onClick={() => {
															if (snSort === "ASC") {
																setSnSort("DESC");
																setNameSort("");
															} else {
																setSnSort("ASC");
																setNameSort("");
															}
														}}
													/>
												</label>
											</div>
										</th>
										<th className="px-2 py-1">Tanggal Masuk</th>
										<th className="px-2 py-1">Status</th>
										<th className="px-2 py-1 flex-grow">Sertifikat</th>
									</tr>
								</thead>
								<tbody>
									{items.map((item) => {
										return (
											<tr className="border-b">
												<td className="px-2 py-4">{item.Type.name}</td>
												<td className="px-2 py-4">{item.serialNumber}</td>
												<td className="px-2 py-4">
													{new Date(item.createdAt).toLocaleDateString("id-ID")}
												</td>
												<td className="px-2 py-4">{item.Status.name}</td>
												{item.StatusId == 5 ? (
													<td
														className="px-2 py-4 text-blue-600 underline cursor-pointer"
														onClick={() =>
															handleDownload(item.id, item.serialNumber)
														}
													>
														{item.Certificates[0].url
															.substring(10)
															.replace(".pdf", "")}
													</td>
												) : (
													<td className="px-2 py-4">Belum tersedia</td>
												)}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					) : (
						<div className="w-full h-full mx-auto items-center justify-center flex">
							<h1 className="text-center py-30">
								Tidak ada item yang dapat ditampilkan
							</h1>
						</div>
					)}
				</div>
			</main>
		</div>
	);
}
