import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../store/actions/actionCreator";
import { ToastContainer, toast } from "react-toastify";

export default function LoginPage() {
	const [form, setForm] = useState();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		setForm(obj);
	};

	function handleLogin(e) {
		e.preventDefault();
		dispatch(login(form))
			.then(() => navigate("/"))
			.catch((error) => {
				console.log(error);
				toast.error(error.message);
			});
	}
	return (
		// <div className="h-screen w-screen flex justify-center items-center bg-blue-200 login">
		// 	<div className="h-1/2 w-1/3 flex flex-col rounded-lg items-center py-4 px-8 gap-10 bg-gray-200">
		// 		<h1 className="text-2xl font-bold mt-4 uppercase tracking-wider">
		// 			Masuk
		// 		</h1>
		// 		<form
		// 			className="flex flex-col gap-8 items-center w-full"
		// 			onSubmit={handleLogin}
		// 		>
		// 			<input
		// 				type="text"
		// 				placeholder="username"
		// 				className="px-4 py-2 rounded-sm shadow-md border w-4/5"
		// 				name="username"
		// 				onChange={changeHandler}
		// 			></input>
		// 			<input
		// 				type="password"
		// 				placeholder="password"
		// 				className="px-4 py-2 rounded-sm shadow-md border w-4/5"
		// 				name="password"
		// 				onChange={changeHandler}
		// 			></input>
		// 			<input
		// 				type="submit"
		// 				value="Login"
		// 				className="bg-themeBlue px-4 py-2 rounded-md text-white shadow-md w-4/5 mt-4 cursor-pointer"
		// 			></input>
		// 		</form>
		// 	</div>
		// </div>
		<div className="h-screen w-screen flex justify-center items-center">
			<div className="h-1/2 w-1/3 flex flex-col rounded-lg items-center py-4 px-8 bg-gray-200">
				<img
					src={require("../assets/sam-logo-removedbg.png")}
					alt="sam-logo"
					className="h-28 w-30 mb-4"
				/>
				{/* <h1 className="font-bold mb-6 text-sm">Portal Sertifikat</h1> */}

				<form
					className="flex flex-col w-full gap-4 items-center"
					onSubmit={handleLogin}
				>
					<input
						type="text"
						placeholder="username"
						className="px-4 py-2 rounded-sm shadow-md border w-4/5"
						name="username"
						onChange={changeHandler}
					></input>
					<input
						type="password"
						placeholder="password"
						className="px-4 py-2 rounded-sm shadow-md border w-4/5"
						name="password"
						onChange={changeHandler}
					></input>
					<input
						type="submit"
						value="Login"
						className="bg-themeBlue px-4 py-2 rounded-md text-white shadow-md w-4/5 mt-4 cursor-pointer"
					></input>
				</form>
			</div>
		</div>
	);
}
