import { combineReducers } from "redux";
import itemReducer from "./itemReducer";
import brandReducer from "./brandReducer";
import typeReducer from "./typeReducer";
import statusReducer from "./statusReducer";


const rootReducer = combineReducers({
    brands: brandReducer,
    types: typeReducer,
    items: itemReducer,
    statuses: statusReducer
})

export default rootReducer