import * as types from "./actionType";
import FileDownload from "js-file-download";
// const baseUrl = "http://localhost:4000/client/";
const baseUrl = "https://api.samkalibrasi.com:4001/client/"
const dateToString = require('../helpers/helpers.js')

const fetchItemsSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_ITEMS,
		payload,
	}
};

export const fetchItems = (input) => {
	let url;
	if (input) {
		const { searchSN, StatusId, TypeId } = input;
		url =
			baseUrl +
			`items?TypeId=${TypeId}&serialNumber=${searchSN}&StatusId=${StatusId}`;
	} else {
		url = baseUrl + "items?page=0";
	}
	return async (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				dispatch(fetchItemsSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

const fetchTypesSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_TYPES,
		payload,
	};
};

const fetchBrandsSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_BRANDS,
		payload,
	};
};

export const fetchTypes = () => {
	let url;
	url = baseUrl + "types";
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.map((el) => {
					return {
						value: el.id,
						label: el.name,
					};
				});
				dispatch(fetchTypesSuccess(result));
			})
			.catch((error) => console.log(error));
	};
};

export const fetchBrands = () => {
	let url;
	url = baseUrl + "brands";
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				// console.log(response)
				return response.json();
			})
			.then((data) => {
				dispatch(fetchBrandsSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

const fetchStatusesSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_STATUSES,
		payload,
	};
};

export const fetchStatuses = () => {
	let url;
	url = baseUrl + "statuses";
	return async (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				dispatch(fetchStatusesSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

export const downloadCertificate = (id, serialNumber, saveas) => {
	let url = baseUrl + `items/${id}/certificate`;
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.blob().then((data) => {
					const fileName = response.headers.get("FileName");
					let url = window.URL.createObjectURL(data),
						anchor = document.createElement("a");
					anchor.href = url;
					anchor.download = saveas;
					FileDownload(data, fileName);
					window.URL.revokeObjectURL(url);
					document.removeChild(anchor);
				});
			}
		});
	};
};

export const downloadAllCertificate = (paths, saveas) => {
	let url = baseUrl + `items/downloadAll`;
	return (dispatch) => {
		return fetch(url, {
			method: "POST",
			headers: {
				access_token: localStorage.access_token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ paths: paths }),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.blob().then((data) => {
					const date = dateToString(new Date());
					const zipFileName = `certificates-${date}.zip`;
					let url = window.URL.createObjectURL(data),
						anchor = document.createElement("a");
					anchor.href = url;
					anchor.download = saveas;
					FileDownload(data, zipFileName);
					window.URL.revokeObjectURL(url);
					document.removeChild(anchor);
				});
			}
		});
	};
};

export const login = (input) => {
	let url = baseUrl + "login";
	return (dispatch) => {
		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(input),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					console.log(data);
					localStorage.setItem("access_token", data.access_token);
					let userData = data.user;
					localStorage.setItem("role", "customer");
					localStorage.setItem("companyName", userData.companyName);
					return data;
				});
			}
		});
	};
};
