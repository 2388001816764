import { useNavigate } from "react-router-dom";

export default function Navbar() {
	const navigate = useNavigate();
	function handleLogout() {
		localStorage.clear();
		navigate("/login");
	}
	return (
		<nav className="w-full px-12 py-10 h-20 border-b flex items-center justify-between bg-themeCream shadow-lg fixed">
			<div className="w-32">
				<img
					src={require("../assets/sam-logo-removedbg.png")}
					className="w-full mt-2"
				/>
			</div>
			<div className="flex flex-col items-center text-md">
				<h1>{localStorage.companyName}</h1>
				<button
					className="py-2 px-4 underline text-xs"
					onClick={handleLogout}
				>
					Keluar
				</button>
			</div>
		</nav>
	);
}
