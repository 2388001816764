import "./App.css";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import store from "../src/store";
import router from "../src/router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	return (
		<Provider store={store}>
			<ToastContainer />
			<RouterProvider router={router} />
		</Provider>
		// <LoginPage />
		// <h1 className="text-3xl font-bold underline">
		//   Hello world!
		// </h1>
	);
}

export default App;
